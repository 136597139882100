import React from "react";
import { graphql } from "gatsby";
import { get, head, takeRight } from "lodash";
import { getUrlFor } from "../utils/imageUtils";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProjectTile from "../components/ProjectTile";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import styles from "../styles/publications.module.scss";
import SectionLayout from "../primitives/SectionLayout";
import ReverseOrderLayout from "../primitives/ReverseOrderLayout";
import LinkWithArrow from "../primitives/LinkWithArrow";
import LeadParagraph from "../primitives/LeadParagraph";
import SocialBar from "../components/SocialBar";

export const query = graphql`
  query PublicationsPageQuery {
    publications: allSanityPublication(
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        ...SanityPublicationFragment
      }
    }
    page: sanityRoute(slug: { current: { eq: "publications" } }) {
      page {
        title
        subtitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaTitle
          metaDescription
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const PublicationsPage = ({ data }) => {
  const { page = {}, publications = {} } = data || {};
  const firstPublication = head(publications.nodes);
  const othersPublications = takeRight(
    publications.nodes,
    publications.nodes.length - 1
  );
  const firstPublicationThumbnailUrl =
    firstPublication.thumbImage &&
    getUrlFor(firstPublication.thumbImage)
      .width(528)
      .height(528)
      .fit("crop")
      .url();

  return (
    <Layout>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />

      <ContentLayout>
        <div className={styles.hero}>
          <h1 className={styles.pageTitle}>Nos publications</h1>
          <article className={styles.heroArticle}>
            <header>
              <ReverseOrderLayout>
                <h1>{firstPublication.title}</h1>
                <p>{firstPublication.subtitle}</p>
              </ReverseOrderLayout>
            </header>
            {firstPublication._rawLeadParagraph && (
              <div className={styles.heroArticleLeadParagraph}>
                <LeadParagraph blocks={firstPublication._rawLeadParagraph} />
              </div>
            )}
            <LinkWithArrow
              to={`/publications/${firstPublication.slug.current}/`}
            >
              Lire l’article
            </LinkWithArrow>
          </article>
          <div
            className={styles.heroArticleImage}
            style={{
              backgroundImage: firstPublicationThumbnailUrl
                ? `url(${firstPublicationThumbnailUrl})`
                : null,
              backgroundColor: firstPublication.backgroundColor
                ? firstPublication.backgroundColor.value
                : null,
            }}
          />
        </div>
        <div className={styles.socialBar}>
          <SocialBar ariaPrefix="publications" />
        </div>
      </ContentLayout>
      <SectionLayout>
        <ContentLayout>
          <div className={styles.publications}>
            {othersPublications.map(
              ({
                title,
                subtitle,
                slug,
                thumbImage,
                _rawLeadParagraph,
                backgroundColor,
                category,
              }) => {
                return (
                  <div
                    className={styles.publication}
                    key={slug ? slug.current : title}
                  >
                    <ProjectTile
                      slug={slug ? `/publications/${slug.current}/` : null}
                      title={title}
                      subtitle={subtitle}
                      thumbnail={thumbImage}
                      leadParagraph={
                        category &&
                        category.includes("publication-scientifique")
                          ? _rawLeadParagraph
                          : null
                      }
                      backgroundColor={backgroundColor}
                    />
                  </div>
                );
              }
            )}
          </div>
        </ContentLayout>
      </SectionLayout>
    </Layout>
  );
};

export default withGraphQLErrors()(PublicationsPage);
